import React from "react";
import { Typography } from "@mui/material";

import "./horizontalScrollBar.css";

const HorizontalScrollBar = ({ products }) => {
  return (
    <div className="ordering__horiScrollBar">
      {products.map((category) => (
        <div key={category.id} className="ordering__horiScrollBar-container">
          <a href={"#".concat(category.id)}>
            <Typography style={{ padding: "10px" }}>
              {category.title}
            </Typography>
          </a>
        </div>
      ))}
    </div>
  );
};

export default HorizontalScrollBar;
