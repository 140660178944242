import React from "react";
import Product from "./Product/Product";
import Grid from "@mui/material/Grid";
import "./products.css";
import { Typography } from "@mui/material";

const Products = ({ products, onAdd, cartItems }) => {
  return (
    <div className="order__Products">
      <Grid container justify="center" spacing={1}>
        {products.map((category) => (
          <Grid
            key={category.id}
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            id={category.id}
          >
            <Typography
              variant="h6"
              style={{ padding: "55px 0 0 5px", fontWeight: 500 }}
            >
              {category.title}
            </Typography>
            <Product category={category} onAdd={onAdd} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Products;
