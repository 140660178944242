import { Typography } from "@mui/material";
import { collection, addDoc, getDocs } from "firebase/firestore";

import React, { useEffect, useState } from "react";
import HeroImage from "../../assets/hero.JPG";
import "./home.css";
import { BottomBar, HorizontalScrollBar, Products } from "../../components";
import fireDB from "../../fireConfig";

const Home = ({ cartItems, onAdd }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function addData() {
    try {
      await addDoc(collection(fireDB, "users"), { name: "Jame", age: 55 });
    } catch (error) {
      console.log(error);
    }
  }

  async function getData() {
    try {
      const products = await getDocs(collection(fireDB, "products"));
      const productsArray = [];
      products.forEach((doc) => {
        const obj = {
          id: doc.id,
          ...doc.data(),
        };
        productsArray.push(obj);
      });

      setProducts(productsArray);

      console.log(productsArray);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div className="order__home">
      <img src={HeroImage} alt="Hero Image" />
      <div>
        <Typography variant="h4">Heidi´s Bier Bar Helsinki</Typography>
      </div>

      <HorizontalScrollBar products={products} />
      <Products products={products} onAdd={onAdd} />
      <BottomBar cartItems={cartItems} />
    </div>
  );
};

export default Home;
