import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./checkout.css";
import { useCart } from "react-use-cart";
import { addDoc, collection } from "firebase/firestore";
import fireDB from "../../fireConfig";

const Checkout = ({
  setFullName,
  fullName,
  setTableNumber,
  tableNumber,
  setSpecialRequest,
  specialRequest,
}) => {
  const { cartTotal } = useCart();
  const { items } = useCart();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const customerDetails = { fullName, tableNumber, specialRequest };

    const orderInfo = {
      customerDetails,
      items,
      cartTotal,
    };

    try {
      const result = await addDoc(collection(fireDB, "orders"), orderInfo);
    } catch (error) {}
  };
  return (
    <div className="order__checkout">
      <form>
        <div className="order__checkout-inputContainer">
          <div className="order__checkout-inputContainer_item">
            <label>Full Name:</label>
            <input
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>

          <div className="order__checkout-inputContainer_item">
            <label>Table Number:</label>
            <input
              type="number"
              value={tableNumber}
              onChange={(e) => setTableNumber(e.target.value)}
              required="required"
            />
          </div>

          <div className="order__checkout-inputContainer_item">
            <label>Special requests:</label>
            <textarea
              rows="6"
              value={specialRequest}
              onChange={(e) => setSpecialRequest(e.target.value)}
            />
          </div>
        </div>

        <div className="order__checkout-moreItems">
          <Link to="/" style={{ textDecoration: "none" }}>
            <Typography>+ Add more items</Typography>
          </Link>
        </div>

        <div className="order__checkout-containerPrice">
          <Typography
            variant="body2"
            style={{ fontWeight: 600, marginBottom: "20px" }}
          >
            Prices in EUR, incl. taxes
          </Typography>

          <div className="order__checkout-price">
            <Typography variant="caption">Items subtotal</Typography>
            <Typography variant="caption">{cartTotal.toFixed(2)}</Typography>
          </div>
          <div className="order__checkout-price">
            <Typography variant="caption">Service fee</Typography>
            <Typography variant="caption">0.20€</Typography>
          </div>
          <div className="order__checkout-price">
            <Typography variant="caption" style={{ fontWeight: 600 }}>
              Total
            </Typography>
            <Typography variant="caption" style={{ fontWeight: 600 }}>
              {(cartTotal + 0.2).toFixed(2)}
            </Typography>
          </div>
        </div>

        <div onClick={handleSubmit}>
          <Link to="/success">
            <div className="order__checkout-payBtn">
              <Button
                variant="contained"
                style={{ textDecoration: "none" }}
                sx={{
                  width: "100%",
                  padding: 1.5,
                }}
              >
                Place order
              </Button>
            </div>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Checkout;
