import React, { useState } from "react";
import { Button, Modal, Typography } from "@mui/material";
import "./product.css";
import { useCart } from "react-use-cart";
import { ProductModal } from "../..";
import Corona from "../../../assets/Corona.png";
import {
  AddCircleRounded,
  RemoveCircleOutlineRounded,
} from "@mui/icons-material";

const min = 1;

const Product = ({ category }) => {
  const { addItem } = useCart();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { inCart } = useCart();
  const [value, setValue] = useState(1);
  const clamp = (min) => (v) => v <= min ? min : v;
  const clampV = clamp(min);
  const { totalItems, items, cartTotal, updateItemQuantity, removeItem } =
    useCart();

  return (
    <div className="odr__product">
      {category.items.map((product) => {
        return (
          <div key={product.id} className="ordering__product-container">
            <div className="ordering__product-container-top">
              <Typography gutterBottom variant="body2">
                {product.title}
              </Typography>
              <div className="ordering__product-container-top_info">
                <div className="ordering__product-container-top_info-details">
                  <Typography variant="caption" color="text.secondary">
                    {product.description}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="primary"
                    sx={{
                      marginTop: 1,
                    }}
                  >
                    {product.price.toFixed(2)}€
                  </Typography>
                </div>

                <div className="ordering__product-container-top_info-image">
                  <img src={Corona} alt="" />
                </div>
              </div>
            </div>
            {!inCart(product.id) && (
              <div className="ordering__product-addToButton">
                <Button
                  onClick={() => {
                    addItem(product, 1);
                  }}
                  variant="outlined"
                >
                  Add product
                </Button>
              </div>
            )}

            {inCart(product.id) && (
              <div className="ordering__product-editButtons">
                {/* <RemoveCircleOutlineRounded
                  color="primary"
                  onClick={() => {
                    addItem(product, 1 - 2);
                  }}
                />

                <Typography sx={{ margin: "0 10px" }} color="primary">
                  {product.quantity}
                </Typography>

                <AddCircleRounded
                  color="primary"
                  onClick={() => {
                    addItem(product, 1);
                  }}
                /> */}

                <Button
                  onClick={() => {
                    addItem(product, 1);
                  }}
                  variant="contained"
                >
                  Add more
                </Button>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Product;
