// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCokv8AgViQ5ajOW153VRctXt4A9Wd8dKc",
  authDomain: "dira-ordering.firebaseapp.com",
  projectId: "dira-ordering",
  storageBucket: "dira-ordering.appspot.com",
  messagingSenderId: "474253531767",
  appId: "1:474253531767:web:484a21b6e64b8d0fed4472",
};

const app = initializeApp(firebaseConfig);

const fireDB = getFirestore(app);

export default fireDB;
