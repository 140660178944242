import { Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useCart } from "react-use-cart";
import "./success.css";

const Success = ({ fullName, tableNumber }) => {
  const { emptyCart } = useCart();
  return (
    <div className="order__success">
      <Typography
        sx={{
          textAlign: "center",
          marginBottom: 3,
        }}
      >
        Thank you {fullName} for your order
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
          marginBottom: 5,
        }}
      >
        Your order will be delivered at table {tableNumber} once ready
      </Typography>
      <div className="order__success-btn">
        <Link to="/">
          <div
            onClick={() => {
              emptyCart();
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: "100%",
                padding: 1.5,
              }}
            >
              HOME
            </Button>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Success;
