import { Button, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { useCart } from "react-use-cart";
import { CartModal } from "..";

import "./bottomBar.css";

const BottomBar = ({ cartItems }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { totalItems, cartTotal } = useCart();

  if (cartTotal === 0) return null;
  return (
    <div className="order__bottomBar">
      <div className="order__bottomBar-button">
        <Button
          variant="contained"
          sx={{
            width: "100%",
            padding: 1.5,
          }}
          onClick={handleOpen}
        >
          <div className="order__bottomBar-content">
            <Typography>{totalItems}</Typography>
            <Typography>View order</Typography>
            <Typography>{cartTotal.toFixed(2)}€</Typography>
          </div>
        </Button>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CartModal open={open} handleClose={handleClose} />
      </Modal>
    </div>
  );
};

export default BottomBar;
