import { Typography } from "@mui/material";
import React from "react";
import { useCart } from "react-use-cart";

const Cart = () => {
  const {
    isEmpty,
    totalUniqueItems,
    totalItems,
    items,
    cartTotal,
    updateItemQuantity,
    removeItem,
    emptyCart,
  } = useCart();
  if (isEmpty) return <Typography>Your cart is empty</Typography>;
  return (
    <div>
      <Typography>Total items {totalItems}</Typography>
      <ul>
        {items.map((item) => (
          <li key={item.id}>
            {item.quantity} x {item.title}
            <button
              onClick={() => updateItemQuantity(item.id, item.quantity - 1)}
            >
              -
            </button>
            <button
              onClick={() => updateItemQuantity(item.id, item.quantity + 1)}
            >
              +
            </button>
            <button onClick={() => removeItem(item.id)}>&times;</button>
          </li>
        ))}
      </ul>

      <Typography>{cartTotal.toFixed(2)}</Typography>
    </div>
  );
};

export default Cart;
