import React from "react";
import "./cartModal.css";
import { useCart } from "react-use-cart";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import {
  AddCircleRounded,
  CancelRounded,
  DeleteOutlined,
  RemoveCircleOutlineRounded,
} from "@mui/icons-material";

const CartModal = ({ handleClose }) => {
  const { totalItems, items, cartTotal, updateItemQuantity, removeItem } =
    useCart();

  return (
    <div className="order__cartModal">
      <div className="oder__cartmodal-container">
        <div className="odr__cartmodal-Container_CloseBtn">
          <CancelRounded
            sx={{
              color: "rgba(32, 33, 37, 0.3)",
              fontSize: 35,
            }}
            onClick={() => handleClose(true)}
          />
        </div>
        <div className="odr__cartmodal-Container_heading cartModalPadding">
          <Typography variant="body2" fontWeight={600} fontSize="17px">
            Total items {totalItems}
          </Typography>
        </div>
        <div className="odr__cartmodal-Container_product">
          <div>
            {items.map((item) => (
              <div className="odr__cartmodal-product" key={item.id}>
                <div className="odr__cartmodal-product-headingDetails cartModalPadding">
                  <Typography variant="body2" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2">
                    {(item.quantity * item.price).toFixed(2)}
                  </Typography>
                </div>

                <div className="cartModalPadding">
                  <div className="odr__cartmodal-Container_product-actions">
                    <div className="odr__cartmodal-Container_product-actions_1">
                      <RemoveCircleOutlineRounded
                        color="primary"
                        onClick={() =>
                          updateItemQuantity(item.id, item.quantity - 1)
                        }
                      />

                      <Typography
                        variant="body2"
                        sx={{ margin: "0 10px" }}
                        color="primary"
                      >
                        {item.quantity}
                      </Typography>

                      <AddCircleRounded
                        color="primary"
                        onClick={() =>
                          updateItemQuantity(item.id, item.quantity + 1)
                        }
                      />
                    </div>

                    <div>
                      <DeleteOutlined
                        color="error"
                        onClick={() => removeItem(item.id)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="odr__cartmodal-Container_button">
          <Link to="/checkout" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              sx={{
                width: "100%",
                padding: 1.5,
              }}
            >
              <div className="odr__cartmodal-Container_bottombar">
                <Typography>{totalItems}</Typography>
                <Typography>Go to checkout</Typography>
                <Typography>{cartTotal.toFixed(2)}€</Typography>
              </div>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CartModal;
