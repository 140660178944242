import Karhu from "../src/assets/Karhu.png";
import Blanc from "../src/assets/Blanc.png";
import Budwiser from "../src/assets/Budwiser.png";
import Heineken from "../src/assets/Heineken.png";
import Carlsberg from "../src/assets/Carlsberg.png";
import Koff from "../src/assets/Koff.png";
import Corona from "../src/assets/Corona.png";

export const productsData = [
  {
    id: "blanc",
    img: Blanc,
    title: "Blanc",
    desc: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.",
    price: 9,
    categoryId: "bottled-beers",
  },
  {
    id: "corona",
    img: Corona,
    title: "Corona",
    desc: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.",
    price: 9.9,
    categoryId: "bottled-beers",
  },
  {
    id: "carlsberg",
    img: Carlsberg,
    title: "Carlsberg",
    desc: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.",
    price: 8.9,
    categoryId: "bottled-beers",
  },

  {
    id: "koff-05l",
    img: Koff,
    title: "Koff 0,5L",
    desc: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.",
    price: 8.9,
    categoryId: "draft-05L",
  },
  {
    id: "karhu",
    img: Karhu,
    title: "Karhu",
    desc: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.",
    price: 8.9,
    categoryId: "draft-05L",
  },
  {
    id: "budwiser",
    img: Budwiser,
    title: "Budwiser",
    desc: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.",
    price: 8.9,
    categoryId: "draft-05L",
  },
  {
    id: "heineken",
    img: Heineken,
    title: "Heineken",
    desc: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.",
    price: 8.9,
    categoryId: "draft-05L",
  },
];

export const categories = [
  {
    id: "bottled-beers",
    title: "Bottled beers",
    productIds: [],
  },
  {
    id: "draft-05L",
    title: "Draft 0.5L",
    productIds: [],
  },
  {
    id: "shots",
    title: "Shots",
    productIds: [],
  },
  {
    id: "wines",
    title: "Wines",
    productIds: [],
  },
];
