import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home, Cart, Checkout, Success } from "./pages";
import { productsData } from "./data";
import { CartProvider } from "react-use-cart";
import { useState } from "react";

function App() {
  const [fullName, setFullName] = useState("");
  const [tableNumber, setTableNumber] = useState("");
  const [specialRequest, setSpecialRequest] = useState("");

  return (
    <div className="App">
      <CartProvider>
        <Router>
          <Routes>
            <Route exact path="/" element={<Home products={productsData} />} />
            <Route
              exact
              path="/cart"
              element={<Cart products={productsData} />}
            />
            <Route
              exact
              path="/checkout"
              element={
                <Checkout
                  setFullName={setFullName}
                  fullName={fullName}
                  setTableNumber={setTableNumber}
                  tableNumber={tableNumber}
                  setSpecialRequest={setSpecialRequest}
                  specialRequest={specialRequest}
                />
              }
            />
            <Route
              exact
              path="/success"
              element={
                <Success fullName={fullName} tableNumber={tableNumber} />
              }
            />
          </Routes>
        </Router>
      </CartProvider>
    </div>
  );
}

export default App;
